import React from "react";
import Slider from "./video-slider/Slider";
import Slider3D from "./video-slider/Slider3D";
import MembershipCard from "./membership/MembershipCard";
import GridHotels from "./Hotels/GridHotels";
import Testimonials from "common/Testimonials";
import ResponsiveImage from "common/ResponsiveImage";
import promosFile from "assets/promos.json";
import "styles/Home.css";

const Home = () => {
  return (
    <>
      <section className="add-margin">
        <Slider showIndicators={true} showButtons={true}>
          {promosFile.map(({ mobil, tablet, pc, large }) => (
            <div className="slide-content" key={pc}>
              <ResponsiveImage
                mobil={mobil}
                tablet={tablet}
                pc={pc}
                large={large}
              />
            </div>
          ))}
        </Slider>
      </section>
      <Slider3D />
      {/* <MembershipCard /> */}
      <GridHotels />
      <Testimonials />
    </>
  );
};

export default Home;
