import React from "react";
import DashboardBanners from "./DashboardBanners";

const Index = () => {
  return (
    <div
      style={{
        height: "100vh",
      }}
    >
      Index ADMIN
      <DashboardBanners />
    </div>
  );
};

export default Index;
