import React, { useState } from "react";
import { Payment } from "components";
import TypePayment from "components/pay/TypePayment";
import ValidateUser from "components/pay/ValidateUser";

const Payments = () => {
  const [user, setUser] = useState(null);
  const [mora, setMora] = useState(null);
  const [formConfig, setFormConfig] = useState({
    step: 1,
  });

  const showView = () => {
    switch (formConfig.step) {
      case 1:
        return (
          <ValidateUser
            setFormConfig={setFormConfig}
            setUser={setUser}
            setMora={setMora}
          />
        );
      case 2:
        return (
          <TypePayment
            setFormConfig={setFormConfig}
            user={user}
            setUser={setUser}
          />
        );
      case 3:
        return (
          <Payment user={user} mora={mora} setFormConfig={setFormConfig} />
        );

      default:
        break;
    }
  };

  return (
    <section className="formContainer">
      <div className="separator mb-4">
        <hr className="line" />
        <p>Plataforma de pagos</p>
        <hr className="line" />
      </div>

      {showView()}
    </section>
  );
};

export default Payments;
