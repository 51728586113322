import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Toaster } from "react-hot-toast";
import { ReactComponent as IconFile } from "assets/icons/file.svg";

const DashboardBanners = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting, touchedFields, dirtyFields },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = handleSubmit(async (data) => {
    console.log(data);
  });
  return (
    <div className="centrar">
      <Toaster />
      <div className="container-contact">
        <div className="row">
          <div className="contenedor-form">
            <img
              loading="lazy"
              src="/images/logo/logo.webp"
              alt="Grupo Alianza Colombia Viajar"
              width="200"
              className="logo1 m-1"
            ></img>
            <h3 className="title-policy fw-bold text-center">
              AGREGAR NUEVO BANNER
            </h3>
            <form onSubmit={onSubmit}>
              <div>
                <label htmlFor="">Imagen Mobil</label>
                <input
                  type="file"
                  accept="image/*"
                  {...register("img_mobile", {
                    required: "El campo Imagen Mobil es obligatorio",
                  })}
                />
              </div>
              <div>
                <label htmlFor="">Imagen Tablet</label>
                <input
                  type="file"
                  accept="image/*"
                  {...register("img_tablet", {
                    required: "El campo Imagen Tablet es obligatorio",
                  })}
                />
              </div>
              <div>
                <label htmlFor="">Imagen PC</label>
                <input
                  type="file"
                  accept="image/*"
                  {...register("img_pc", {
                    required: "El campo Imagen PC es obligatorio",
                  })}
                />
              </div>
              <div>
                <label htmlFor="">Imagen pantallas grandes</label>
                <input
                  type="file"
                  accept="image/*"
                  {...register("img_large", {
                    required:
                      "El campo Imagen pantallas grandes es obligatorio",
                  })}
                />
              </div>
              <div>
                <label htmlFor="">Link banner</label>
                <input type="text" {...register("link")} />
              </div>

              <button
                className="btn-login"
                style={{ width: "100%", padding: ".6em" }}
                disabled={loading}
              >
                {loading ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  ></div>
                ) : (
                  "Enviar"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardBanners;
