import React, { useState, useEffect } from "react";
import { ReactComponent as IconRobot } from "assets/icons/robot.svg";
import { ReactComponent as IconAsk } from "assets/icons/preguntas_frecuentes.svg";
import { ReactComponent as IconCall } from "assets/icons/call.svg";
import { ReactComponent as IconWhatsapp } from "assets/icons/whatsapp.svg";
import { ReactComponent as IconCorreo } from "assets/icons/correo.svg";
import { ReactComponent as IconPQRS } from "assets/icons/pqrs.svg";
import "styles/robot/menu-robot.css";
import { NavLink } from "react-router-dom";

const MenuRobot = () => {
  const [showIcons, setShowIcons] = useState(false);
  const [iconsVisible, setIconsVisible] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const toggleIcons = () => {
    setShowIcons(!showIcons);
  };

  useEffect(() => {
    if (showIcons) {
      const timeouts = [];
      for (let i = 0; i < iconsVisible.length; i++) {
        timeouts.push(
          setTimeout(() => {
            setIconsVisible((prev) => {
              const newIconsVisible = [...prev];
              newIconsVisible[i] = true;
              return newIconsVisible;
            });
          }, i * 200)
        );
      }

      return () => {
        timeouts.forEach((timeout) => clearTimeout(timeout));
      };
    } else {
      setIconsVisible([false, false, false, false, false]);
    }
  }, [showIcons]);

  return (
    <div className="robot-float">
      <div
        className={`${showIcons ? "border-menu" : ""}`}
        onClick={toggleIcons}
      >
        <div onClick={toggleIcons} className="icon-robot">
          <IconRobot />
        </div>
      </div>

      <div className={`icons-container ${showIcons ? "show" : ""}`}>
        <NavLink
          to="/questions"
          className={`icon-item ${iconsVisible[0] ? "visible" : ""}`}
          data-tooltip="Preguntas Frecuentes"
        >
          <IconAsk />
        </NavLink>
        <a
          href="tel:+6015520100"
          className={`icon-item ${iconsVisible[1] ? "visible" : ""}`}
          data-tooltip="Llamar"
          rel="noopener noreferrer"
        >
          <IconCall />
        </a>
        <a
          href="https://wa.link/pu34su"
          target="_blank"
          className={`icon-item ${iconsVisible[2] ? "visible" : ""}`}
          data-tooltip="WhatsApp"
          rel="noopener noreferrer"
        >
          <IconWhatsapp />
        </a>
        <a
          href="mailto:sac@grupoalianzacolombia.com"
          className={`icon-item ${iconsVisible[3] ? "visible" : ""}`}
          data-tooltip="Correo"
        >
          <IconCorreo />
        </a>
        <NavLink
          to="pqrs"
          className={`icon-item ${iconsVisible[4] ? "visible" : ""}`}
          data-tooltip="PQRS"
        >
          <IconPQRS />
        </NavLink>
      </div>
    </div>
  );
};

export default MenuRobot;
